import React, { useEffect, useState } from "react";
import { Card, Tabs, Spin } from "antd";
import RT, { PageControl } from "./../component/RajangTable";

const Component = (props: any) => {
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [cdetail, setCDetail]: any = useState(props.cdetail);
  const [industryList, setIndustryList] = useState([]);
  const [companysnapshot, setCompanySnapShot] = useState(props.companysnapshot);
  const [industysnapshot, setIndustySnapshot] = useState(props.industysnapshot);
  const [companydocument, setCompanyDocument] = useState(props.companydocument);
  const [historicalcompanysnapshot, setHistoricalCompanySnapShot] = useState(
    props.historicalcompanysnapshot
  );
  const [competitor, setCompetitor] = useState(props.competitor);
  const [loading, setLoading] = useState(false);
  const [csloading, setCSLoading] = useState(false);
  const [isloading, setISLoading] = useState(false);
  const [cdloading, setCDLoading] = useState(false);
  const [hcsloading, setHCSLoading] = useState(false);
  const [tabsloading, setTabsLoading] = useState(false);

  useEffect(() => {
    setCDetail(props.cdetail);
    setIndustryList(props.industrylist);
    setCompetitor(props.competitor);
    setCompanySnapShot(props.companysnapshot);
    setIndustySnapshot(props.industysnapshot);
    setCompanyDocument(props.companydocument);
    setHistoricalCompanySnapShot(props.historicalcompanysnapshot);
    setLoading(props.loading);
    setCSLoading(props.csloading);
    setISLoading(props.isloading);
    setCDLoading(props.cdloading);
    setHCSLoading(props.hcsloading);
    setTabsLoading(props.tabsloading);
  }, [
    props.cdetail,
    props.industrylist,
    props.companysnapshot,
    props.competitor,
    props.industysnapshot,
    props.historicalcompanysnapshot,
    props.loading,
    props.companydocument,
    props.csloading,
    props.isloading,
    props.cdloading,
    props.hscloading,
  ]);

  const descMapping = (el: any) => {
    let answer = industryList.find((o: any) => {
      return o["value"] === el;
    });
    if (!answer) {
      return "";
    }
    if (!answer["label"]) {
      return "";
    }
    return answer["label"];
  };

  const getDisplay = (el: any) => {
    if (el) {
      return el.map((d: any) => {
        return (
          <React.Fragment key={d._id}>
            {ComRenderRow(descMapping(d))}
          </React.Fragment>
        );
      });
    }
  };

  const ComRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div>{indus}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getCOMDisplay = (el: any) => {
    if (el) {
      return el.map((d: any) => {
        return (
          <React.Fragment key={d._id}>
            {CompetitorRenderRow(d.name, d.compnumber)}
          </React.Fragment>
        );
      });
    }
  };

  const CompetitorRenderRow = (name: any, compnumber: any) => {
    if (name && compnumber) {
      return (
        <>
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              switchCompany(name, compnumber);
            }}
          >
            {name}
          </div>
        </>
      );
    } else if (name) {
      return (
        <>
          <div>{name}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const switchCompany = (fname: any, fcompnumber: any) => {
    if (props.switchCompany && typeof props.switchCompany === "function") {
      props.switchCompany({ name: fname, compnumber: fcompnumber });
    }
  };

  const handleDownload = (data: any) => {
    if (props.handleDownload && typeof props.handleDownload === "function") {
      props.handleDownload(data);
    }
  };

  return (
    <div style={{ width: "80%", margin: "auto", paddingTop: "0px" }}>
      <p style={{ textAlign: "left", fontSize: "20px", fontWeight: "700" }}>
        Search Results:
      </p>
      <Card
        bordered={true}
        style={{ height: "100%", border: "1px solid rgba(0, 0, 0, 1)" }}
        loading={loading}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            textAlign: "left",
            border: "1px solid rgba(0, 0, 0, 1)",
            height: "290px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateRows: "10% 10% 80%",
              borderRight: "1px solid rgba(0, 0, 0, 1)",
            }}
          >
            <div
              style={{
                paddingLeft: "5px",
                fontWeight: "700",
                borderBottom: "1px solid rgba(0, 0, 0, 1)",
                fontSize: "16px",
                backgroundColor: "rgba(217, 217, 217, 1)",
              }}
            >
              Company
            </div>
            <div
              style={{
                paddingLeft: "5px",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              {cdetail?.name || ""} ({cdetail?.compnumber || ""})
            </div>
            <div
              style={{
                paddingLeft: "5px",
                fontSize: "16px",
                overflow: "auto",
                height: "200px",
                margin: "1%",
              }}
            >
              {competitor.description || ""}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "10% 35% 55%",
              borderRight: "1px solid rgba(0, 0, 0, 1)",
            }}
          >
            <div
              style={{
                paddingLeft: "5px",
                fontWeight: "700",
                borderBottom: "1px solid rgba(0, 0, 0, 1)",
                fontSize: "16px",
                backgroundColor: "rgba(217, 217, 217, 1)",
              }}
            >
              Industry
            </div>
            <div
              style={{
                paddingLeft: "5px",
                display: "grid",
                gridTemplateRows: "40% 60%",
                borderBottom: "1px dotted rgba(0, 0, 0, 1)",
              }}
            >
              <div style={{ fontWeight: "700", fontSize: "18px" }}>
                Primary Industry
              </div>
              <div style={{ fontSize: "17px" }}>
                {descMapping(cdetail?.industry) || ""}
              </div>
            </div>
            <div
              style={{
                paddingLeft: "5px",
                display: "grid",
                gridTemplateRows: "20% 80%",
              }}
            >
              <div style={{ fontWeight: "700", fontSize: "17px" }}>
                Associated Industry
              </div>
              <div style={{ fontSize: "18px", overflow: "auto" }}>
                {getDisplay(cdetail?.subindustry) || ""}
              </div>
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateRows: "10% 90%" }}>
            <div
              style={{
                paddingLeft: "5px",
                fontWeight: "700",
                borderBottom: "1px solid rgba(0, 0, 0, 1)",
                fontSize: "16px",
                backgroundColor: "rgba(217, 217, 217, 1)",
              }}
            >
              Relationship
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "0.5fr 1fr" }}>
              <div
                style={{
                  paddingLeft: "5px",
                  fontWeight: "700",
                  borderRight: "1px dotted rgba(0, 0, 0, 1)",
                  fontSize: "17px",
                }}
              >
                Competitors
              </div>
              <div style={{ paddingLeft: "5px" }}>
                <div style={{ fontSize: "18px" }}>Companies</div>
                <div style={{ overflow: "auto", height: "200px" }}>
                  {getCOMDisplay(competitor?.competitors) || ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "50px" }}>
          {tabsloading ? (
            <Spin size="large" />
          ) : (
            <Tabs
              type="card"
              items={[
                {
                  label: <>Company Snapshot ({companysnapshot?.length})</>,
                  key: "1",
                  // disabled: companysnapshot.length > 0 ? false : true,
                  children: (
                    <RT
                      tablewidth="100%"
                      pageControl={pageC}
                      data={companysnapshot}
                      cols={[
                        {
                          fieldname: "publishdate",
                          width: "15%",
                          label: "Publication date",
                          sortcolumn: "Date",
                          clickable: "true",
                          align: "center",
                          button: "true",
                        },
                        {
                          fieldname: "distributors",
                          width: "10%",
                          label: "Distributors",
                          align: "left",
                        },
                        // {
                        //   fieldname: "relevant_dates",
                        //   width: "20%",
                        //   label: "Relevant Date(h)",
                        //   type: "Array_Date",
                        // },
                        {
                          fieldname: "relevant_dates",
                          width: "30%",
                          label: "Relevant Date",
                          type: "Array_String",
                        },
                        {
                          fieldname: "relevant_names",
                          width: "45%",
                          label: "Relevant Name",
                          type: "Array_String",
                        },
                      ]}
                      handleDownload={handleDownload}
                      loading={csloading}
                      type={"small"}
                      breadcrumb={props.breadcrumb}
                    ></RT>
                  ),
                },
                {
                  // label: "Industry Snapshot",
                  label: <>Industry Snapshot ({industysnapshot?.length})</>,
                  key: "2",
                  // disabled: industysnapshot.length > 0 ? false : true,
                  children: (
                    <RT
                      tablewidth="100%"
                      pageControl={pageC}
                      data={industysnapshot}
                      cols={[
                        {
                          fieldname: "publishdate",
                          width: "10%",
                          label: "Publication date",
                          sortcolumn: "Date",
                          clickable: "true",
                          align: "center",
                          button: "true",
                        },
                        {
                          fieldname: "countryname",
                          width: "15%",
                          label: "Country",
                          align: "left",
                          lookup: props.dropDownCountryOptions,
                        },
                        {
                          fieldname: "distributors",
                          width: "10%",
                          label: "Distributors",
                          align: "left",
                        },
                        // {
                        //   fieldname: "relevant_dates",
                        //   width: "20%",
                        //   label: "Relevant Date(h)",
                        //   type: "Array_Date",
                        // },
                        {
                          fieldname: "relevant_dates",
                          width: "30%",
                          label: "Relevant Date",
                          type: "Array_String",
                        },
                        {
                          fieldname: "relevant_names",
                          width: "45%",
                          label: "Relevant Name",
                          type: "Array_String",
                        },
                      ]}
                      handleDownload={handleDownload}
                      loading={isloading}
                      type={"small"}
                      breadcrumb={props.breadcrumb}
                    ></RT>
                  ),
                },
                {
                  // label: "Company Document",
                  label: <>Company Document ({companydocument?.length})</>,
                  key: "3",
                  // disabled: companydocument.length > 0 ? false : true,
                  children: (
                    <RT
                      tablewidth="100%"
                      pageControl={pageC}
                      data={companydocument}
                      cols={[
                        {
                          fieldname: "publishdate",
                          width: "20%",
                          label: "Publication date",
                          sortcolumn: "Date",
                          clickable: "true",
                          align: "left",
                        },
                        {
                          fieldname: "documenttypecode",
                          width: "30%",
                          label: "Document Type",
                          align: "left",
                          sortcolumn: "String",
                        },
                        {
                          fieldname: "distributors",
                          width: "50%",
                          label: "Distributors",
                          align: "left",
                        },
                      ]}
                      loading={cdloading}
                      type={"small"}
                      breadcrumb={props.breadcrumb}
                    ></RT>
                  ),
                },
                {
                  // label: "Company Document",
                  label: (
                    <>
                      Historical Company Snapshot (
                      {historicalcompanysnapshot?.length})
                    </>
                  ),
                  key: "4",
                  // disabled: companydocument.length > 0 ? false : true,
                  children: (
                    <RT
                      tablewidth="100%"
                      pageControl={pageC}
                      data={historicalcompanysnapshot}
                      cols={[
                        {
                          fieldname: "publishdate",
                          width: "15%",
                          label: "Publication date",
                          sortcolumn: "Date",
                          clickable: "true",
                          align: "center",
                          button: "true",
                        },
                        {
                          fieldname: "distributors",
                          width: "10%",
                          label: "Distributors",
                          align: "left",
                        },
                        {
                          fieldname: "relevant_dates",
                          width: "30%",
                          label: "Relevant Date",
                          type: "Array_String",
                        },
                        {
                          fieldname: "relevant_names",
                          width: "45%",
                          label: "Relevant Name",
                          type: "Array_String",
                        },
                      ]}
                      handleDownload={handleDownload}
                      loading={hcsloading}
                      type={"small"}
                      breadcrumb={props.breadcrumb}
                    ></RT>
                  ),
                },
              ]}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Component;
