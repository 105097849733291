import axios from "axios";
import { AuthHeader } from "./header";

const url = process.env.REACT_APP_SERVER_URL as string;

const searchurl: any = url + "/company";
export const getSearchResult = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(searchurl, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const allcompanyurl: string = url + "/company/allcompany";
export const getAllCompany = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(allcompanyurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const limiturl: any = url + "/company/get";
export const getSearchLimitResult = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(limiturl, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const countryurl: string = url + "/country";
export const getAllCountry = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(countryurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const limitcountryurl: string = url + "/country/get";
export const getLimitCountry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(limitcountryurl, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const fileindexurl: string = url + "/fileindex/getindex";
export const getFileIndexCountry = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(fileindexurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const limitfileindexurl: string = url + "/fileindex/getcountry";
export const getLimitCountryFileIndex = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(limitfileindexurl, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const industryurl: string = url + "/industry";
export const getAllIndustry = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(industryurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const limitindustryurl: string = url + "/industry/get";
export const getLimitIndustry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(limitindustryurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const csdetail: string = url + "/joblog/cs";
export const getCompanySnapShot = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(csdetail, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const isdetail: string = url + "/joblog/is";
export const getIndustrySnapShot = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(isdetail, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const cddetail: string = url + "/joblog/cd";
export const getCompanyDocument = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(cddetail, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const hcsdetail: string = url + "/joblog/hcs";
export const getHistoricalCompanySnapShot = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(hcsdetail, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const csjob: string = url + "/joblog/csjob";
export const getCSDistributeSummary = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(csjob, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const csjobdt: string = url + "/joblog/csjobdate";
export const getCSDistributerDeliveryDate = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(csjobdt, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const joblogdetail: string = url + "/joblog/get";
export const getJobLog = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(joblogdetail, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const competitors: string = url + "/competitor";
export const getCompetitors = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(competitors, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const documenttypes: string = url + "/documenttype";
export const getDocumenttypes = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(documenttypes, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};
