import React, { useEffect, useState } from "react";
import "./RajangTable.css";
import {
  Input,
  Select,
  Table,
  TablePaginationConfig,
  Button,
  DatePicker,
  Space,
  Typography,
  Modal,
  Tooltip,
  Dropdown,
} from "antd";
import type { MenuProps } from "antd";
import {
  SearchOutlined,
  RollbackOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  CloseCircleFilled,
  DoubleRightOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import DetailModal from "./DetailsModal";
import AlertModal from "../component/AlertMessage";
import { deleteMultiCompany } from "../controller/delete";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ConfigManagerDetail from "../component/ConfigManagerDetail";
import { updateConfig, deleteConfig } from "../controller/config";
import { deleteProfile } from "../controller/profile";
import { getCountDocument } from "../controller/company";
import { getAllIndustry } from "../controller/search";
import { DownloadOutlined } from "@ant-design/icons";
import IO from "../lib/socketio";
const { Column } = Table;
const { RangePicker } = DatePicker;
const { Paragraph, Text } = Typography;

export interface PageControl {
  numRec: number;
  recPerPage: number;
  pageNum: number;
}

const Component = (props: any) => {
  const [currentPage, setCurrentPage] = useState(props.pageControl.pageNum);
  const [currentNumPerPage, setcurrentNumPerPage] = useState(
    props.pageControl.recPerPage
  );
  const [allChecked, setAllChecked]: any = useState([]);
  const [selectAll, setSelectAll]: any = useState(false);
  const [redraw, setRedraw] = useState(true);
  const [shownFilters, setShownFilters] = useState([]);
  const [dataFilter, setDataFilter]: any = useState({});
  const [sortControl, setSortControl]: any = useState([]);
  const [pdata, setData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCPModal, setShowCPModal] = useState(false);
  const [showCPPModal, setShowCPPModal] = useState(false);
  const [showIPModal, setShowIPModal] = useState(false);
  const [showDRModal, setShowDRModal] = useState(false);
  const [showUModal, setShowUModal] = useState(false);
  const [filteredValue, setFilterValue]: any = useState({});
  const [changed, setChanged] = useState(false);
  const [is_data, setIs_data] = useState(props.is_data);
  const [countrylist, setCountryList]: any = useState([]);
  const [industrylist, setIndustryList]: any = useState([]);
  const [namelist, setNameList]: any = useState([]);
  const [emaillist, setEmailList]: any = useState([]);
  const [lastEditBylist, setLastEditByList]: any = useState([]);
  const [rolelist, setRoleList]: any = useState([]);
  const [companylist, setCompanyList]: any = useState([]);
  const [removeData, setRemoveData]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showDConfirm, setShowDConfirm] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [openMore, setOpenMore] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const getbaseUrl = window.location.pathname;
  const navigate = useNavigate();
  const location = useLocation();
  let breadcrumb = props.breadcrumb ? props.breadcrumb : [];
  const [expand, setExpand] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo]: any = useState({});
  const [selectedOptions, setSelectedOptions]: any = useState(
    props.selectedOption
  );

  const { Paragraph } = Typography;
  type Props = {
    formattedDates: string;
  };
  const ShowMoreLess: React.FC<Props> = ({ formattedDates }) => {
    let newformattedDates = formattedDates.toString();
    const [expand, setExpand] = useState(false);
    const [isContentCovered, setIsContentCovered] = useState(false);
    const handleEllipsis = (ellipsis: boolean) => {
      setIsContentCovered(ellipsis);
    };

    const toggleExpand = () => {
      setExpand(!expand);
      setIsInverted(!isInverted);
    };
    const [isInverted, setIsInverted] = useState(false);

    return (
      <div style={{ display: "flex" }}>
        <Paragraph
          ellipsis={
            expand
              ? false
              : {
                  rows: 2,
                  expandable: false,
                  symbol: "Show more",
                  onEllipsis: handleEllipsis,
                }
          }
          style={{
            display: "inline-block",
            marginRight: "8px",
            marginBottom: 0,
          }}
        >
          {newformattedDates}
        </Paragraph>
        {isContentCovered && (
          <button
            className="button-2"
            style={{
              transform: isInverted ? "rotate(180deg)" : "none",
            }}
            onClick={toggleExpand}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16.7"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="7 15 12 20 17 15"></polygon>
            </svg>
          </button>
        )}
      </div>
    );
  };

  const items: MenuProps["items"] = [
    {
      label: "File Inventory",
      key: "file_inventory",
      icon: <FileExcelOutlined />,
    },
    {
      label: "Daily Documents",
      key: "daily_document",
      icon: <FileExcelOutlined />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    getexportCSV(key);
  };

  // useEffect(() => {
  //   IO.on("reset_company_filter", (msg: any) => {
  //     resetFilterHome();
  //   });
  //   return () => {
  //     IO.off("reset_company_filter");
  //   };
  // });

  // const [timeout, setTimeout]:any =useState(null)

  // useEffect(() => {
  //   setExpand(expand);
  // }, [expand]);

  // console.log("props", props.data);

  useEffect(() => {
    if (props.cols) {
      props.cols.map((fobj: any, i: any) => {
        const fname: string = fobj.fieldname;
        const flookup: string = fobj.lookup;
        if (fname && flookup && fname === "country") {
          setCountryList(flookup);
        } else if (fname && flookup && fname === "industry") {
          setIndustryList(flookup);
        } else if (fname && flookup && fname === "name") {
          setNameList(flookup);
        } else if (fname && flookup && fname === "email") {
          setEmailList(flookup);
        } else if (fname && flookup && fname === "lastEditBy") {
          setLastEditByList(flookup);
        } else if (fname && flookup && fname === "rolelist") {
          setRoleList(flookup);
        }
      });
    }
  }, [props.cols]);

  useEffect(() => {
    if (props.listing) {
      props.listing.map((fobj: any, i: any) => {
        setCountryList(fobj.countrylist);
        setIndustryList(fobj.industrylist);
        setCompanyList(fobj.companylist);
        setNameList(fobj.namelist);
        setEmailList(fobj.emaillist);
        setLastEditByList(fobj.lastEditBy);
        setRoleList(fobj.rolelist);
      });
    }
  }, [props.listing]);

  // const [select, setSelect] = useState({
  //     selectedRowKeys:[],
  //     loading:false
  // })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
  // useEffect(()=>{
  //     setSelectedRowKeys(props.selectedData)
  // },[props.selectedData])
  // const {selectedRowKeys, loading} = select
  // console.log("selectedkey",selectedRowKeys)
  // console.log("tableis_data",is_data)
  useEffect(() => {
    setIs_data(props.is_data);
  }, [props.is_data]);
  useEffect(() => {
    setSelectedRowKeys(props.selectedData);
  }, [props.selectedData]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [props.selectednewindustrycode]);

  const ConfirmChanges = (el: any) => {
    setChanged(el);
  };

  // const fetchIndustryData = async () => {
  //   getAllIndustry().then((res: any) => {
  //     if (res.data) {
  //       const dropDownIndustryOption = res.data.map((x: any, i: any) => ({
  //         label: x.description,
  //         value: x.industrycode,
  //       }));
  //       setIndustryList(dropDownIndustryOption);
  //     }
  //   });
  // };

  const handleClear = () => {
    // Your custom logic here
    console.log("Clear button clicked!");
  };

  useEffect(() => {
    if (props.handleLoadData && typeof props.handleLoadData === "function") {
      if (dataFilter.length !== 0) {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        setChanged(false);
        // getAllIndustry().then((res: any) => {
        //   if (res.data) {
        //     const dropDownIndustryOption = res.data.map((x: any, i: any) => ({
        //       label: x.description,
        //       value: x.industrycode,
        //     }));
        //     setIndustryList(dropDownIndustryOption);
        //   }
        // });
        props.handleLoadData(tempPageControl, dataFilter, sortControl);
      } else {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        setChanged(false);
        // getAllIndustry().then((res: any) => {
        //   if (res.data) {
        //     const dropDownIndustryOption = res.data.map((x: any, i: any) => ({
        //       label: x.description,
        //       value: x.industrycode,
        //     }));
        //     setIndustryList(dropDownIndustryOption);
        //   }
        // });
        props.handleLoadData(tempPageControl, sortControl);
      }
    }
  }, [changed]);

  const checkLabel = (key: string) => {
    return props.cols.map((fobj: any, i: any) => {
      const fname: string = fobj.fieldname;
      const flabel: string = fobj.label;
      if (fname && fname === key) {
        return flabel;
      }
    });
  };

  useEffect(() => {
    if (props.resetValue === true) {
      resetFilter();
      if (props.restoreFilter && typeof props.restoreFilter === "function") {
        props.restoreFilter();
      }
    }
  }, [props.resetValue]);

  const checkFilename = (k: any) => {
    if (Array.isArray(k)) {
      return k[0].replace(".pdf", ".docx");
      // Use modifiedFilenames array as needed
    } else {
      // Handle single filename
      return k.replace(".pdf", ".docx");
      // Use modifiedFilename as needed
    }
  };
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    let sortObj: any = {};
    if (sorter.order !== undefined && sorter.order) {
      sortObj[sorter.field] = sorter.order === "ascend" ? 1 : -1;
      setSortControl(sortObj);
      setSortedInfo(sorter);
    } else {
      setSortedInfo({});
    }
  };
  const checkSelected = (idx: any) => {
    return allChecked.indexOf(`${idx}`) >= 0;
  };
  const handleManageDefaultConfig: any = () => {
    setShowDetailModal(!showDetailModal);
  };

  const handleClose: any = () => {
    setShowDetailModal(false);
  };

  const handleSaveData = (data: any) => {
    if (data) {
      updateConfig(data).then((res: any) => {
        if (res.data) {
          // GetAlertType("Success")
          handleAlertType("Success");
          // GetAlertMessage(`${res.data.name} has been created`)
          handleAlertMessage(`${res.data.distributionName} has been updated`);
          ConfirmChanges(true);
        } else {
          handleAlertType("Alert");
          // GetAlertType("Error")
          // GetAlertMessage(`Duplicate Company Number.Company Number must be unique and numeric.`)
          handleAlertMessage(
            `${res.data.distributionName} has not been updated`
          );
          ConfirmChanges(true);
        }
      });
    }
  };
  const handleCancel: any = () => {
    console.log("modal cancelled");
    setShowDetailModal(false);
  };
  const toggleChecked = (idx: any) => {
    const oldValues = allChecked;
    if (oldValues.indexOf(`${idx}`) >= 0) {
      oldValues.splice(oldValues.indexOf(`${idx}`), 1);
    } else {
      oldValues.push(`${idx}`);
    }
    setAllChecked(oldValues);
    setRedraw(!redraw);
  };
  const checkLookUp = (el: any) => {
    props.cols.map((fobj: any, i: any) => {
      const fname: string = fobj.fieldname;
      const flookup: string = fobj.lookup;
      if (fname && flookup && fname === el) {
        return flookup;
      }
    });
  };

  const toggleSelectAll = () => {
    const tempState = !selectAll;
    const allSelected: any = [];
    if (tempState) {
      props.data.map((v: any, idx: number) => {
        allSelected.push(`${idx}`);
      });
      setSelectAll(tempState);
      setAllChecked(allSelected);
    } else {
      setSelectAll(tempState);
      setAllChecked([]);
    }

    setRedraw(!redraw);
  };
  const showFilter = (fname: string) => {
    const tempValues: any = shownFilters;
    if (tempValues.indexOf(fname) < 0) {
      tempValues.push(fname);
    } else {
      tempValues.splice(tempValues.indexOf(fname), 1);
    }
    // console.log("temp", tempValues);
    // setShownFilters(tempValues);
    setRedraw(!redraw);
  };
  const handleKeyDown = (e: any, fname: any) => {
    if (e.key === "Enter") {
      setFilter(fname, e.target.value);
    }
  };
  const updateHandleFilter = () => {
    if (props.handleFilter && typeof props.handleFilter === "function") {
      const tempPageControl: PageControl = props.pageControl;
      setCurrentPage(1);
      tempPageControl.pageNum = currentPage;
      const tempValue: any = dataFilter;
      Object.keys(tempValue).map((f: string, i: any) => {
        if (tempValue[f] === "") delete tempValue[f];
      });
      const SortValue: any = sortControl;
      Object.keys(SortValue).map((f: string, i: any) => {
        if (SortValue[f] === "") delete SortValue[f];
      });
      props.handleFilter(tempValue, tempPageControl, SortValue);
    }
    if (
      props.handleMultiFilter &&
      typeof props.handleMultiFilter === "function"
    ) {
      const tempValue: any = dataFilter;
      props.handleMultiFilter(tempValue);
    }
  };
  const setFilter = (fname: string, _filterValue: any) => {
    // console.log("fname", "_filterValue");
    const tempValue = dataFilter;
    const filter: any = filteredValue || {};
    if (typeof _filterValue === "number" || _filterValue === undefined) {
      tempValue[fname] = _filterValue;
    } else {
      tempValue[fname] = _filterValue.trim();
    }
    if (_filterValue !== undefined) {
      filter[fname] = [_filterValue];
      // console.log("filter[fname]", filter[fname]);
      // console.log("filter", filter);
      setFilterValue(filter);
    } else {
      setFilterValue([]);
    }
    updateHandleFilter();
    setDataFilter(tempValue);
    setRedraw(!redraw);
  };
  const removeFilter = (fname: string) => {
    const tempValue = dataFilter;
    delete tempValue[fname];
    setDataFilter(tempValue);
    setRedraw(!redraw);
    updateHandleFilter();
  };

  // const renderHeaderCell = (fname: string, index: number) => {
  //   const tempValues: any = shownFilters;
  //   return (
  //     <td key={`td${index}`}>
  //       {index === 0 && props.hasSelectAll === true && (
  //         <>
  //           <input type="checkbox" onChange={() => toggleSelectAll()}></input>
  //         </>
  //       )}

  //       {tempValues && tempValues.indexOf(fname) >= 0 && (
  //         <>
  //           {props.lookup !== undefined && props.lookup[fname] && (
  //             <Select
  //               listHeight={160}
  //               getPopupContainer={(trigger) => trigger.parentElement}
  //               placement="bottomLeft"
  //               style={{ width: "100%", backgroundColor: "#FFFFFF" }}
  //               showSearch={true}
  //               placeholder="..."
  //               optionFilterProp="children"
  //               defaultValue={dataFilter[fname] || null}
  //               onChange={(ev: any) => {
  //                 // console.log(ev)
  //                 setFilter(fname, ev);
  //               }}
  //               filterOption={(input: any, option: any) => {
  //                 return (option?.label ?? "")
  //                   .toLowerCase()
  //                   .includes(input.toLowerCase());
  //               }}
  //               options={props.lookup[fname]}
  //             ></Select>
  //           )}
  //           {(props.lookup === undefined ||
  //             props.lookup[fname] === undefined) && (
  //             <input
  //               type="text"
  //               defaultValue={dataFilter[fname] || ""}
  //               onChange={(ev: any) => {
  //                 setFilter(fname, ev.target.value);
  //               }}
  //               onBlur={() => showFilter(fname)}
  //             ></input>
  //           )}
  //         </>
  //       )}
  //       {tempValues && tempValues.indexOf(fname) < 0 && (
  //         <span onClick={() => showFilter(fname)}>
  //           {checkLabel(fname)}
  //           {dataFilter[fname] && <>({dataFilter[fname]})</>}
  //         </span>
  //       )}
  //     </td>
  //   );
  // };

  // const sortdate: any = (el: any) => {
  //   if (el === undefined || el === null) {
  //     return null;
  //   } else {
  //     let aa: any = [];
  //     const sorted = el.sort((a: any, b: any) => {
  //       return new Date(b[0]).getTime() - new Date(a[0]).getTime();
  //     });
  //     // }
  //     return sorted;
  //   }
  // };

  // const formatDate = (el: any, type: any) => {
  //   if (type === "Y") {
  //     return new Date(el).getFullYear();
  //   } else if (type === "M") {
  //     return (
  //       new Date(el).getFullYear() +
  //       "/" +
  //       ("0" + (new Date(el).getMonth() + 1)).slice(-2)
  //     );
  //   } else {
  //     return el;
  //   }
  // };

  const handleSelectChange = (value: any, record: any) => {
    // setSelectedOptions((prevState: any) => ({
    //   ...prevState,
    //   [record._id]: value,
    // }));
    // console.log("value", value);
    // console.log("record", record);
    const tempSelectedOptions = {
      ...selectedOptions,
      [record._id]: value,
    };
    setSelectedOptions(tempSelectedOptions);
    if (
      props.handleSelectedOption &&
      typeof props.handleSelectedOption === "function"
    ) {
      props.handleSelectedOption(tempSelectedOptions);
    }
  };

  const handleEditSelectChange = (value: any, record: any) => {
    // console.log("value", value);
    // console.log("record", record);
    record._code = value;
    // console.log("dasda", selectedOptions);
    const tempSelectedOptions = {
      ...selectedOptions,
      [record._id]: value,
    };
    // console.log("tempSelectedOptions", tempSelectedOptions);
    setSelectedOptions(tempSelectedOptions);
    // if (
    //   props.handleRefreshSelect &&
    //   typeof props.handleRefreshSelect === "function"
    // ) {
    //   props.handleRefreshSelect(record);
    // }
    if (
      props.handleEditSelectedOptions &&
      typeof props.handleEditSelectedOptions === "function"
    ) {
      props.handleEditSelectedOptions(tempSelectedOptions);
    }
  };

  const handleEditDeSelectChange = (value: any, record: any) => {
    // console.log("devalue", value);
    // console.log("derecord", record);
    if (
      props.handleDeSelectedOptions &&
      typeof props.handleDeSelectedOptions === "function"
    ) {
      props.handleDeSelectedOptions(value);
    }
  };

  const filteredOptions = (record: any, options: any) => {
    // console.log("record", record);
    // const selectedValues = selectedOptions["value"] || [];
    // console.log("selectedValues", selectedValues);
    // console.log(
    //   "abc",
    //   options.filter((item: any) => !props.selectedOption.includes(item.label))
    // );
    // console.log("options", options);
    // console.log("psoptions", props.selectedOption);

    let newoptions = options.filter(
      (item: any) => !props.selectedOption.includes(item.label)
    );
    // console.log("bew", newoptions);

    return newoptions;
  };

  const renderLargeColumns = () => {
    if (props.cols) {
      return props.cols.map((fobj: any, index: number) => {
        const fname: string = fobj.fieldname;
        const colwidth: string = fobj.width || "1fr";
        const flookup: any = fobj.lookup;
        const sortcol: string = fobj.sortcolumn;
        const clickable: any = fobj.clickable;
        const falign: any = fobj.align;
        const ffilter: any = fobj.filter;
        const tempValues: any = shownFilters;
        if (tempValues && tempValues.indexOf(fname) >= 0) {
          if (flookup !== undefined && flookup) {
            return (
              <>
                {sortcol !== undefined && sortcol === "true" && (
                  <Column
                    align="left"
                    width={colwidth}
                    title={
                      <Select
                        listHeight={160}
                        placement="bottomLeft"
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                            .parentElement.parentElement
                        }
                        dropdownMatchSelectWidth={false}
                        style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                        showSearch
                        autoFocus
                        allowClear
                        placeholder="..."
                        optionFilterProp="children"
                        defaultValue={dataFilter[fname] || ""}
                        onChange={(ev: any) => {
                          setFilter(fname, ev);
                        }}
                        filterOption={(input: any, option: any) => {
                          return (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        options={flookup}
                        defaultOpen
                        onBlur={() => showFilter(fname)}
                      ></Select>
                    }
                    showSorterTooltip={false}
                    sortDirections={["ascend", "descend", "ascend"]}
                    dataIndex={fname}
                    key={fname}
                  />
                )}
                {sortcol === undefined && (
                  <Column
                    align="left"
                    width={colwidth}
                    title={
                      <Select
                        listHeight={160}
                        placement="bottomLeft"
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                        }
                        style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                        showSearch
                        autoFocus
                        allowClear
                        placeholder="..."
                        optionFilterProp="children"
                        defaultValue={dataFilter[fname] || ""}
                        onChange={(ev: any) => {
                          // console.log(ev)
                          setFilter(fname, ev);
                        }}
                        filterOption={(input: any, option: any) => {
                          return (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        options={flookup}
                        defaultOpen
                        onBlur={() => showFilter(fname)}
                      ></Select>
                    }
                    showSorterTooltip={false}
                    sortDirections={["ascend", "descend", "ascend"]}
                    dataIndex={fname}
                    key={fname}
                  />
                )}
              </>
            );
          } else {
            return (
              <>
                {sortcol !== undefined && sortcol === "true" && (
                  <Column
                    width={colwidth}
                    title={
                      <Tooltip title="Filtering with Enter">
                        <Input
                          type="text"
                          defaultValue={dataFilter[fname] || ""}
                          onChange={(ev: any) => {
                            if (ev.target.value === "") {
                              setFilter(fname, ev.target.value);
                            }
                          }}
                          onKeyDown={(e) => handleKeyDown(e, fname)}
                          onBlur={() => showFilter(fname)}
                          allowClear
                          autoFocus
                        />
                      </Tooltip>
                    }
                    dataIndex={fname}
                    key={fname}
                    showSorterTooltip={false}
                    sortDirections={["ascend", "descend", "ascend"]}
                  />
                )}
                {sortcol === undefined && (
                  <Column
                    width={colwidth}
                    title={
                      <Tooltip title="Filtering with Enter">
                        <Input
                          type="text"
                          defaultValue={dataFilter[fname] || ""}
                          onChange={(ev: any) => {
                            if (ev.target.value === "") {
                              setFilter(fname, ev.target.value);
                            }
                          }}
                          onKeyDown={(e) => handleKeyDown(e, fname)}
                          onBlur={() => showFilter(fname)}
                          allowClear
                          autoFocus
                        />
                      </Tooltip>
                    }
                    sortDirections={["ascend", "descend", "ascend"]}
                    showSorterTooltip={false}
                    dataIndex={fname}
                    key={fname}
                  />
                )}
              </>
            );
          }
        } else {
          if (sortcol !== undefined && sortcol === "true") {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  showSorterTooltip={false}
                  sorter={true}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  align={falign}
                  render={(text: any, record: any) => {
                    return (
                      <a
                        style={{
                          border: "solid 0px",
                          textDecoration: "underline",
                          color: "#000000",
                        }}
                        onClick={() => selectData(record, fname)}
                      >
                        <div>{text}</div>
                      </a>
                    );
                  }}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  showSorterTooltip={false}
                  sorter={true}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  align={falign}
                />
              );
            }
          } else {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <a
                        style={{
                          border: "solid 0px",
                          textDecoration: "underline",
                          color: "#000000",
                        }}
                        onClick={() => selectData(record, fname)}
                      >
                        <div>{text}</div>
                      </a>
                    );
                  }}
                  align={falign}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  align={falign}
                />
              );
            }
          }
        }
      });
    } else {
      return props.fields.map((fname: string, index: number) => {
        const tempValues: any = shownFilters;
        if (tempValues && tempValues.indexOf(fname) >= 0) {
          if (props.lookup !== undefined && props.lookup[fname]) {
            return (
              <Column
                align="left"
                title={
                  <Select
                    listHeight={160}
                    placement="bottomLeft"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    showSearch={true}
                    placeholder="..."
                    optionFilterProp="children"
                    defaultValue={dataFilter[fname] || null}
                    onChange={(ev: any) => {
                      setFilter(fname, ev);
                    }}
                    filterOption={(input: any, option: any) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={props.lookup[fname]}
                  ></Select>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          } else {
            return (
              <Column
                title={
                  <input
                    type="text"
                    defaultValue={dataFilter[fname] || ""}
                    onChange={(ev: any) => {
                      setFilter(fname, ev.target.value);
                    }}
                    onBlur={() => showFilter(fname)}
                  ></input>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          }
        } else {
          if (
            props.sortColumn !== undefined &&
            props.sortColumn.indexOf(fname) >= 0
          ) {
            return (
              <Column
                title={
                  <span
                    onClick={() => showFilter(fname)}
                    className="column-header"
                  >
                    {checkLabel(fname)}
                    {dataFilter[fname] && <>({dataFilter[fname]})</>}
                    &nbsp;&nbsp;
                    <SearchOutlined />
                  </span>
                }
                dataIndex={fname}
                key={fname}
                showSorterTooltip={false}
                sorter={true}
                sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                sortDirections={["ascend", "descend", "ascend"]}
              />
            );
          } else {
            return (
              <Column
                title={
                  <span
                    onClick={() => showFilter(fname)}
                    className="column-header"
                  >
                    {checkLabel(fname)}
                    {dataFilter[fname] && <>({dataFilter[fname]})</>}
                    &nbsp;&nbsp;
                    <SearchOutlined />
                  </span>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          }
        }
      });
    }
  };

  const renderSmallColumns = () => {
    if (props.cols) {
      return props.cols.map((fobj: any, index: number) => {
        const fname: any = fobj.fieldname;
        const colwidth: string = fobj.width || "1fr";
        const flookup: any = fobj.lookup;
        const sortcol: string = fobj.sortcolumn;
        const clickable: any = fobj.clickable;
        const falign: any = fobj.align;
        const ffilter: any = fobj.filter;
        const ftype: any = fobj.type;
        const tempValues: any = shownFilters;
        const fselectMultiple: any = fobj.selectMultiple;
        const fmode: any = fobj.mode;
        const fbutton: any = fobj.button;
        const filterValue: any = filteredValue[fname] || [];
        if (tempValues && tempValues.indexOf(fname) >= 0) {
          if (flookup !== undefined && flookup) {
            return (
              <Column
                align="left"
                width={colwidth}
                title={
                  <Select
                    listHeight={160}
                    placement="bottomLeft"
                    getPopupContainer={(trigger) =>
                      trigger.parentElement.parentElement.parentElement
                        .parentElement.parentElement
                    }
                    style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    showSearch={true}
                    autoFocus={true}
                    placeholder="..."
                    optionFilterProp="children"
                    defaultValue={dataFilter[fname] || ""}
                    onChange={(ev: any, index: any) => {
                      if (index !== undefined) {
                        setFilter(fname, index.label);
                      } else {
                        setFilter(fname, ev);
                      }
                    }}
                    filterOption={(input: any, option: any) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={flookup}
                    allowClear
                    onBlur={() => showFilter(fname)}
                  ></Select>
                }
                dataIndex={fname}
                key={fname}
                filteredValue={filterValue}
                showSorterTooltip={false}
                sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                // sorter={(a: any, b: any) =>
                //   a[`${fname}`]
                //     .toString()
                //     .localeCompare(b[`${fname}`].toString())
                // }
                onFilter={(value: any, record: any) => {
                  return record[`${fname}`].indexOf(value) === 0;
                }}
                render={(text: any, record: any) => {
                  if (fselectMultiple) {
                    return (
                      <Select
                        listHeight={160}
                        placement="bottomLeft"
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                            .parentElement.parentElement
                        }
                        showSearch
                        mode="multiple"
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                          border: "0px solid",
                        }}
                        placeholder="Select Industry Code"
                        optionFilterProp="children"
                        value={
                          Array.isArray(record._code)
                            ? record._code.map((item: any) => item)
                            : []
                        }
                        onChange={(value: any) => {
                          handleSelectChange(value, record);
                        }}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={filteredOptions(record, fselectMultiple).map(
                          (item: any) => ({
                            label: item.label,
                            value: item.value,
                          })
                        )}
                      />
                    );
                  } else {
                    return <div>{text}</div>;
                  }
                }}
              />
            );
          } else {
            return (
              <Column
                width={colwidth}
                title={
                  <Input
                    type="text"
                    defaultValue={dataFilter[fname] || ""}
                    onChange={(ev: any) => {
                      setFilter(fname, ev.target.value);
                    }}
                    onBlur={() => showFilter(fname)}
                    allowClear
                    autoFocus
                  />
                }
                dataIndex={fname}
                key={fname}
                filteredValue={filterValue}
                showSorterTooltip={false}
                sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                onFilter={(value: any, record: any) => {
                  return record[`${fname}`].indexOf(value) === 0;
                }}
              />
            );
          }
        } else {
          if (sortcol !== undefined && sortcol === "String") {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  showSorterTooltip={false}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sorter={(a: any, b: any) =>
                    a[`${fname}`]
                      .toString()
                      .localeCompare(b[`${fname}`].toString())
                  }
                  sortDirections={["ascend", "descend", "ascend"]}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) => {
                    return record[`${fname}`].indexOf(value) === 0;
                  }}
                  render={(text: any, record: any) => {
                    return (
                      <a
                        style={{
                          border: "solid 0px",
                          textDecoration: "underline",
                          color: "#000000",
                        }}
                        onClick={() => selectData(record, fname)}
                      >
                        <div>{text}</div>
                      </a>
                    );
                  }}
                />
              );
            } else if (clickable === "false") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  showSorterTooltip={false}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sorter={(a: any, b: any) =>
                    a[`${fname}`]
                      .toString()
                      .localeCompare(b[`${fname}`].toString())
                  }
                  sortDirections={["ascend", "descend", "ascend"]}
                  align={falign}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  showSorterTooltip={false}
                  sorter={(a: any, b: any) =>
                    a[`${fname}`]
                      .toString()
                      .localeCompare(b[`${fname}`].toString())
                  }
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) => {
                    return record[`${fname}`].indexOf(value) === 0;
                  }}
                  render={(text: any, record: any) => {
                    return (
                      <>
                        {text === "Pending" && (
                          <div style={{ color: "rgba(47, 167, 205, 1)" }}>
                            {text}
                          </div>
                        )}
                        {text === "Declined" && (
                          <div style={{ color: "rgba(223, 70, 98, 1)" }}>
                            {text}
                          </div>
                        )}
                        {text !== "Pending" && text !== "Declined" && (
                          <div>{text}</div>
                        )}
                      </>
                    );
                  }}
                />
              );
            }
          } else if (sortcol !== undefined && sortcol === "Number") {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <a
                        style={{
                          border: "solid 0px",
                          textDecoration: "underline",
                          color: "#000000",
                        }}
                        onClick={() => selectData(record, fname)}
                      >
                        <div>{text}</div>
                      </a>
                    );
                  }}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) =>
                    record[`${fname}`].indexOf(value) === 0
                  }
                  sorter={(a: any, b: any) => a[`${fname}`] - b[`${fname}`]}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  showSorterTooltip={false}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  align={falign}
                  sorter={(a: any, b: any) => a[`${fname}`] - b[`${fname}`]}
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  showSorterTooltip={false}
                  sortDirections={["ascend", "descend", "ascend"]}
                  // filteredValue={filterValue}
                  // onFilter={(value:any,record:any)=>
                  //     record[`${fname}`].indexOf(value)===0
                  // }
                />
              );
            }
          } else if (sortcol !== undefined && sortcol === "Date") {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <>
                        {text !== "" && (
                          <div>
                            <a
                              style={{
                                border: "solid 0px",
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                              onClick={() => {
                                selectData(record, fname);
                              }}
                            >
                              <div>{convertDate(text)} </div>
                            </a>
                            {fbutton === "true" && (
                              <>
                                <Tooltip title="Click to download EN version file">
                                  <Button
                                    shape="circle"
                                    size="small"
                                    className="translate-en"
                                    onClick={() => {
                                      props.data.map((k: any, i: any) => {
                                        if (record.key === k._id) {
                                          if (
                                            props.handleDownload &&
                                            typeof props.handleDownload ===
                                              "function"
                                          ) {
                                            props.handleDownload({
                                              doctype: "docx",
                                              doccode: k.doccode,
                                              _id: k._id,
                                              version: "EN",
                                              filename: k.filename.replace(
                                                ".pdf",
                                                ".docx"
                                              ),
                                            });
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    &nbsp;
                                  </Button>
                                </Tooltip>
                                {/* <Tooltip title="Click to download CN version file">
                                  <Button
                                    shape="circle"
                                    size="small"
                                    className="translate-cn"
                                  >
                                    &nbsp;
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Click to download JA version file">
                                  <Button
                                    shape="circle"
                                    size="small"
                                    className="translate-ja"
                                  >
                                    &nbsp;
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Click to download DE version file">
                                  <Button
                                    shape="circle"
                                    size="small"
                                    className="translate-de"
                                  >
                                    &nbsp;
                                  </Button>
                                </Tooltip> */}
                              </>
                            )}
                          </div>
                        )}

                        {text === "" && (
                          <div>
                            <a
                              style={{
                                border: "solid 0px",
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                              onClick={() => selectData(record, fname)}
                            >
                              <div>{text}</div>
                            </a>
                          </div>
                        )}
                      </>
                    );
                  }}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) =>
                    record[`${fname}`].indexOf(value) === 0
                  }
                  sorter={(a: any, b: any) =>
                    new Date(a[`${fname}`]).getTime() -
                    new Date(b[`${fname}`]).getTime()
                  }
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  showSorterTooltip={false}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <>
                        {text !== "" && (
                          <div>
                            <div>{convertDate(text)}</div>
                          </div>
                        )}
                        {text === "" && (
                          <div>
                            <div>{text}</div>
                          </div>
                        )}
                      </>
                    );
                  }}
                  align={falign}
                  sorter={(a: any, b: any) =>
                    new Date(a[`${fname}`]).getTime() -
                    new Date(b[`${fname}`]).getTime()
                  }
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  showSorterTooltip={false}
                  // filteredValue={filterValue}
                  // onFilter={(value:any,record:any)=>
                  //     record[`${fname}`].indexOf(value)===0
                  // }
                />
              );
            }
          } else if (sortcol !== undefined && sortcol === "Datetime") {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <>
                        {text !== null && (
                          <div>
                            <a
                              style={{
                                border: "solid 0px",
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                              onClick={() => selectData(record, fname)}
                            >
                              <div>{convertDatetime(text)}</div>
                            </a>
                          </div>
                        )}
                        {text === null && (
                          <div>
                            <a
                              style={{
                                border: "solid 0px",
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                              onClick={() => selectData(record, fname)}
                            >
                              <div>
                                <i>No Date</i>
                              </div>
                            </a>
                          </div>
                        )}
                      </>
                    );
                  }}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) =>
                    record[`${fname}`].indexOf(value) === 0
                  }
                  sorter={(a: any, b: any) =>
                    new Date(a[`${fname}`]).getTime() -
                    new Date(b[`${fname}`]).getTime()
                  }
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  sortDirections={["ascend", "descend", "ascend"]}
                  showSorterTooltip={false}
                />
              );
            } else {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <>
                        {text !== null && (
                          <div>
                            <div>{convertDatetime(text)}</div>
                          </div>
                        )}
                        {text === null && (
                          <div>
                            <div>
                              <i>No Date</i>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                  align={falign}
                  sorter={(a: any, b: any) =>
                    new Date(a[`${fname}`]).getTime() -
                    new Date(b[`${fname}`]).getTime()
                  }
                  sortOrder={sortedInfo.columnKey === fname && sortedInfo.order}
                  showSorterTooltip={false}
                  sortDirections={["ascend", "descend", "ascend"]}
                />
              );
            }
          } else {
            if (clickable !== undefined && clickable === "true") {
              return (
                <Column
                  width={colwidth}
                  title={
                    <>
                      {ffilter && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            showFilter(fname);
                          }}
                          className="column-header"
                        >
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                          &nbsp;&nbsp;
                          <SearchOutlined />
                        </span>
                      )}
                      {!ffilter && (
                        <span className="column-header">
                          {checkLabel(fname)}
                          {dataFilter[fname] && (
                            <>({getLabel(fname, dataFilter[fname], flookup)})</>
                          )}
                        </span>
                      )}
                    </>
                  }
                  dataIndex={fname}
                  key={fname}
                  render={(text: any, record: any) => {
                    return (
                      <a
                        style={{
                          border: "solid 0px",
                          textDecoration: "underline",
                          color: "#000000",
                        }}
                        onClick={() => selectData(record, fname)}
                      >
                        <div>{text}</div>
                      </a>
                    );
                  }}
                  align={falign}
                  filteredValue={filterValue}
                  onFilter={(value: any, record: any) =>
                    record[`${fname}`].indexOf(value) === 0
                  }
                />
              );
            } else {
              if (ftype === "Array_Date") {
                return (
                  <Column
                    width={colwidth}
                    title={
                      <>
                        {ffilter && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              showFilter(fname);
                            }}
                            className="column-header"
                          >
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                            &nbsp;&nbsp;
                            <SearchOutlined />
                          </span>
                        )}
                        {!ffilter && (
                          <span className="column-header">
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                          </span>
                        )}
                      </>
                    }
                    dataIndex={fname}
                    key={fname}
                    align={falign}
                    render={(relevant_dates: any) => {
                      if (
                        relevant_dates === undefined ||
                        relevant_dates === null
                      ) {
                        return null;
                      } else {
                        const formattedDates = relevant_dates.map(
                          (k: any, index: any) => {
                            if (index === relevant_dates.length - 1) {
                              return k;
                            } else {
                              return `${k}\n\r`;
                            }
                          }
                        );
                        return <ShowMoreLess formattedDates={formattedDates} />;
                      }
                    }}
                  />
                );
              } else if (ftype === "Array_String") {
                return (
                  <Column
                    width={colwidth}
                    title={
                      <>
                        {ffilter && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              showFilter(fname);
                            }}
                            className="column-header"
                          >
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                            &nbsp;&nbsp;
                            <SearchOutlined />
                          </span>
                        )}
                        {!ffilter && (
                          <span className="column-header">
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                          </span>
                        )}
                      </>
                    }
                    dataIndex={fname}
                    key={fname}
                    align={falign}
                    render={(relevant_dates: any) => {
                      if (
                        relevant_dates === undefined ||
                        relevant_dates === null
                      ) {
                        return null;
                      } else {
                        const formattedDates = relevant_dates.map(
                          (k: any, index: any) => {
                            if (index === relevant_dates.length - 1) {
                              return k;
                            } else {
                              return `${k}\n\r`;
                            }
                          }
                        );
                        return <ShowMoreLess formattedDates={formattedDates} />;
                      }
                    }}
                  />
                );
              } else if (fselectMultiple !== undefined && fselectMultiple) {
                if (fmode !== undefined) {
                  return (
                    <Column
                      width={colwidth}
                      title={
                        <>
                          {ffilter && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                showFilter(fname);
                              }}
                              className="column-header"
                            >
                              {checkLabel(fname)}
                              {dataFilter[fname] && (
                                <>
                                  ({getLabel(fname, dataFilter[fname], flookup)}
                                  )
                                </>
                              )}
                              &nbsp;&nbsp;
                              <SearchOutlined />
                            </span>
                          )}
                          {!ffilter && (
                            <span className="column-header">
                              {checkLabel(fname)}
                              {dataFilter[fname] && (
                                <>
                                  ({getLabel(fname, dataFilter[fname], flookup)}
                                  )
                                </>
                              )}
                            </span>
                          )}
                        </>
                      }
                      dataIndex={fname}
                      key={fname}
                      showSorterTooltip={false}
                      sortOrder={
                        sortedInfo.columnKey === fname && sortedInfo.order
                      }
                      sorter={(a: any, b: any) =>
                        a[`${fname}`]
                          .toString()
                          .localeCompare(b[`${fname}`].toString())
                      }
                      sortDirections={["ascend", "descend", "ascend"]}
                      align={falign}
                      filteredValue={filterValue}
                      onFilter={(value: any, record: any) => {
                        return record[`${fname}`].indexOf(value) === 0;
                      }}
                      render={(text: any, record: any) => {
                        return (
                          <Select
                            listHeight={160}
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            placement="bottomLeft"
                            showSearch
                            mode="multiple"
                            style={{
                              width: "100%",
                              height: "100%",
                              // textAlign: "left",
                              // border: "0px solid",
                            }}
                            placeholder="Select Industry Code"
                            optionFilterProp="children"
                            value={
                              Array.isArray(record._code)
                                ? record._code.map((item: any) => item)
                                : []
                            }
                            onChange={(value: any) => {
                              handleSelectChange(value, record);
                            }}
                            filterOption={(input: any, option: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={filteredOptions(
                              record,
                              fselectMultiple
                            ).map((item: any) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                          />
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <Column
                      width={colwidth}
                      title={
                        <>
                          {ffilter && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                showFilter(fname);
                              }}
                              className="column-header"
                            >
                              {checkLabel(fname)}
                              {dataFilter[fname] && (
                                <>
                                  ({getLabel(fname, dataFilter[fname], flookup)}
                                  )
                                </>
                              )}
                              &nbsp;&nbsp;
                              <SearchOutlined />
                            </span>
                          )}
                          {!ffilter && (
                            <span className="column-header">
                              {checkLabel(fname)}
                              {dataFilter[fname] && (
                                <>
                                  ({getLabel(fname, dataFilter[fname], flookup)}
                                  )
                                </>
                              )}
                            </span>
                          )}
                        </>
                      }
                      dataIndex={fname}
                      key={fname}
                      showSorterTooltip={
                        sortedInfo.order === "ascend"
                          ? { title: "Sort Descending" }
                          : { title: "Sort Ascending (Empty Rows First)" }
                      }
                      sortOrder={
                        sortedInfo.columnKey === fname && sortedInfo.order
                      }
                      sorter={(a: any, b: any) =>
                        a[`${fname}`]
                          .toString()
                          .localeCompare(b[`${fname}`].toString())
                      }
                      sortDirections={["ascend", "descend", "ascend"]}
                      align={falign}
                      filteredValue={filterValue}
                      onFilter={(value: any, record: any) => {
                        return record[`${fname}`].indexOf(value) === 0;
                      }}
                      render={(text: any, record: any) => {
                        return (
                          <Select
                            listHeight={160}
                            placement="bottomLeft"
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            showSearch
                            mode="multiple"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "left",
                              border: "0px solid",
                            }}
                            placeholder="Select Industry Code"
                            optionFilterProp="children"
                            value={
                              Array.isArray(record._code)
                                ? record._code.map((item: any) => item)
                                : []
                            }
                            onChange={(value: any) => {
                              handleEditSelectChange(value, record);
                            }}
                            onDeselect={(value: any) => {
                              handleEditDeSelectChange(value, record);
                            }}
                            filterOption={(input: any, option: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={filteredOptions(
                              record,
                              fselectMultiple
                            ).map((item: any) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                          />
                        );
                      }}
                    />
                  );
                }
              } else {
                return (
                  <Column
                    width={colwidth}
                    title={
                      <>
                        {ffilter && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              showFilter(fname);
                            }}
                            className="column-header"
                          >
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                            &nbsp;&nbsp;
                            <SearchOutlined />
                          </span>
                        )}
                        {!ffilter && (
                          <span className="column-header">
                            {checkLabel(fname)}
                            {dataFilter[fname] && (
                              <>
                                ({getLabel(fname, dataFilter[fname], flookup)})
                              </>
                            )}
                          </span>
                        )}
                      </>
                    }
                    dataIndex={fname}
                    key={fname}
                    align={falign}
                    // filteredValue={filterValue}
                    // onFilter={(value:any,record:any)=>
                    //     record[`${fname}`].indexOf(value)===0
                    // }
                  />
                );
              }
            }
          }
        }
      });
    } else {
      return props.fields.map((fname: string, index: number) => {
        const tempValues: any = shownFilters;
        if (tempValues && tempValues.indexOf(fname) >= 0) {
          if (props.lookup !== undefined && props.lookup[fname]) {
            return (
              <Column
                align="left"
                title={
                  <Select
                    listHeight={160}
                    placement="bottomLeft"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    showSearch={true}
                    placeholder="..."
                    optionFilterProp="children"
                    defaultValue={dataFilter[fname] || null}
                    onChange={(ev: any) => {
                      setFilter(fname, ev);
                    }}
                    filterOption={(input: any, option: any) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={props.lookup[fname]}
                  ></Select>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          } else {
            return (
              <Column
                title={
                  <input
                    type="text"
                    defaultValue={dataFilter[fname] || ""}
                    onChange={(ev: any) => {
                      setFilter(fname, ev.target.value);
                    }}
                    onBlur={() => showFilter(fname)}
                  ></input>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          }
        } else {
          if (
            props.sortColumn !== undefined &&
            props.sortColumn.indexOf(fname) >= 0
          ) {
            return (
              <Column
                title={
                  <span
                    onClick={() => showFilter(fname)}
                    className="column-header"
                  >
                    {checkLabel(fname)}
                    {dataFilter[fname] && <>({dataFilter[fname]})</>}
                    &nbsp;&nbsp;
                    <SearchOutlined />
                  </span>
                }
                dataIndex={fname}
                key={fname}
                showSorterTooltip={false}
                sorter={true}
              />
            );
          } else {
            return (
              <Column
                title={
                  <span
                    onClick={() => showFilter(fname)}
                    className="column-header"
                  >
                    {checkLabel(fname)}
                    {dataFilter[fname] && <>({dataFilter[fname]})</>}
                    &nbsp;&nbsp;
                    <SearchOutlined />
                  </span>
                }
                dataIndex={fname}
                key={fname}
              />
            );
          }
        }
      });
    }
  };
  if (props.checkGotanyData != null) {
    if (props.checkGotanyData() === true) {
      const box = document.querySelector("thead.ant-table-thead .ant-checkbox");
      if (box != null) {
        box.className = "ant-checkbox ant-checkbox-indeterminate";
      }
    }
  }

  const convertDate = (el: any) => {
    let d = new Date(el);
    return d.toLocaleDateString("en-GB");
  };

  const convertDatetime = (el: any) => {
    let d = new Date(el);
    return d.toLocaleString("en-GB");
  };

  const checkDataFilter = (rec: any) => {
    const tempValue = dataFilter;
    Object.keys(tempValue).map((f: any, i: any) => {
      if (tempValue[f] === "") {
        delete tempValue[f];
      }
    });
    if (Object.keys(tempValue).length === 0) {
      return true;
    } else {
      const matches = Object.keys(tempValue).map((f: string, i: any) => {
        const re = RegExp(tempValue[f], "i");
        return re.test(rec[f]);
      });
      return matches.indexOf(true) >= 0;
    }
  };
  const getLabel = (key: any, value: any, flookup: any) => {
    if (flookup) {
      let answer = flookup.find((d: any) => {
        if (Array.isArray(value)) {
          return d["value"] === value[0];
        } else {
          return d["value"] === value;
        }
      });
      if (!answer) {
        return value;
      }
      if (!answer["label"]) {
        return value;
      }
      return answer["label"];
    }
    return value;
  };

  const getLabelData: any = (col: any, data: any) => {
    let newArray: any = [];
    if (data) {
      data.map((rec: any, i: any) => {
        let obj: any = {};
        obj["_id"] = rec._id;
        obj["key"] = rec._id;
        col.forEach((f: any) => {
          const fname: any = f.fieldname;
          const flookup: any = f.lookup;
          if (f.selectMultiple) {
            obj[fname] = rec[fname];
          } else {
            obj[fname] = getLabel(fname, rec[fname], flookup);
          }
        });
        newArray.push(obj);
      });
      return newArray;
    }
  };
  const getPageRecords = () => {
    const pn = currentPage;
    const recFrom =
      pn * props.pageControl.recPerPage - props.pageControl.recPerPage + 1;
    let recTo = recFrom + (props.pageControl.recPerPage - 1);
    if (recTo >= props.pageControl.numRec) {
      recTo = props.pageControl.numRec;
    }
    return [recFrom, recTo];
  };
  const goPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const goNextPage = () => {
    const maxPage: number = Math.floor(
      props.pageControl.numRec / props.pageControl.recPerPage
    );
    if (currentPage <= maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const changeNumberperPage = (el: any) => {
    setcurrentNumPerPage(el);
  };
  const changeNumberPage = (current: any, el: any) => {
    setcurrentNumPerPage(el);
  };
  const changePages = (page: any) => {
    setCurrentPage(page);
  };
  // const rowSelection =(selectedRowKeys)=>{
  //     onChange: (selectedRowKeys:any) => {
  //       setSelect({
  //         ...select,
  //         selectedRowKeys: selectedRowKeys
  //       });
  //     }
  // };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', newSelectedRowKeys);
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    let newlist = Array.from(new Set(newSelectedRowKeys));
    setRemoveData(newlist);
    setSelectedRowKeys(newSelectedRowKeys);
    // console.log("setSelectedRowKeys", selectedRowKeys);
    if (props.handleSelected && typeof props.handleSelected == "function") {
      // console.log("call function set the row data keys")
      // console.log("selectedRowKeys",newSelectedRowKeys)
      props.handleSelected(newlist);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const checkSelection: any = () => {
    if (props.rowSelection && props.rowSelection === "true") {
      return rowSelection;
    } else {
      return undefined;
    }
  };
  useEffect(() => {
    if (props.handleLoadData && typeof props.handleLoadData === "function") {
      if (dataFilter) {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.pageNum = currentPage;
        props.handleLoadData(tempPageControl, dataFilter, sortControl);
      } else {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.pageNum = currentPage;
        props.handleLoadData(tempPageControl, sortControl);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (props.handleLoadData && typeof props.handleLoadData === "function") {
      if (dataFilter) {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, dataFilter, sortControl);
      } else {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, sortControl);
      }
    }
  }, [currentNumPerPage]);

  useEffect(() => {
    if (props.handleLoadData && typeof props.handleLoadData === "function") {
      if (dataFilter) {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, dataFilter, sortControl);
      } else {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, sortControl);
      }
    }
  }, [sortControl]);

  useEffect(() => {
    if (props.handleLoadData && typeof props.handleLoadData === "function") {
      if (dataFilter.length !== 0) {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, dataFilter, sortControl);
      } else {
        const tempPageControl: PageControl = props.pageControl;
        tempPageControl.recPerPage = currentNumPerPage;
        props.handleLoadData(tempPageControl, sortControl);
      }
    }
  }, [dataFilter]);

  const showemptyonly = () => {
    let newc = {
      column: props.cols[0],
      columnKey: "_code",
      field: "_code",
      order: "ascend",
    };
    setSortedInfo(newc);
  };

  const resetFilterHome = () => {
    setDataFilter({});
    setFilterValue([]);
    setSelectedRowKeys([]);
    onSelectChange([]);
    changePages(1);
    if (getbaseUrl === "/home") {
      onChange({}, {}, { order: "ascend", field: "name" }, {});
    }
  };

  const resetFilter = () => {
    setDataFilter({});
    setFilterValue([]);
    setSelectedRowKeys([]);
    onSelectChange([]);
    changePages(1);

    if (
      getbaseUrl == "/country_publication" ||
      getbaseUrl === "/industry_publication" ||
      getbaseUrl === "/industry_publication_" ||
      getbaseUrl === "/country_publication_" ||
      getbaseUrl == "/industry_mapping"
    ) {
      let newc = {
        column: props.cols[0],
        columnKey: "_id",
        field: "_id",
      };
      setSortedInfo(newc);
    } else if (
      getbaseUrl == "/user_management" ||
      getbaseUrl == "/configuration_gui"
    ) {
      let newc = {
        column: props.cols[0],
        columnKey: "name",
        field: "name",
      };
      setSortedInfo(newc);
    }
  };

  const createNew = () => {
    setShowCreateModal(true);
  };

  const deleteAll = () => {
    let newArr: any = [];
    props.removeData.map((el: any, i: any) => {
      newArr.push(...el);
    });
    let newlist: any = Array.from(new Set(newArr));
    // setRemoveData(newlist);
    // setShowDConfirm(true);
    // console.log("RD", removeData);
    if (props.deleteOutside) {
      if (props.handleDelete && typeof props.handleDelete === "function") {
        props.handleDelete(newlist);
      }
    } else {
      Modal.confirm({
        ...props.deleteConfirmation,
        okText: "Delete",
        onOk() {
          removeMultiData(newlist);
        },
      });
    }
  };

  const selectData: any = (data: any, fname: any) => {
    if (getbaseUrl === "/home") {
      if (fname === "name") {
        props.data.map((k: any, i: any) => {
          if (data.key === k._id) {
            setLoading(true);
            setShowCreateModal(true);

            getCountDocument({ compnumber: k.compnumber })
              .then((res: any) => {
                if (res.data) {
                  const mergedObj = Object.assign({}, k, res.data);
                  setData(mergedObj);
                  setLoading(false);
                }
              })
              .catch((err: any) => {
                console.error(err);
              });
          }
        });
      }
    } else if (getbaseUrl === "/country_publication") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          setData(k);
          setShowCPModal(true);
        }
      });
    } else if (getbaseUrl === "/industry_publication") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          setData(k);
          setShowIPModal(true);
        }
      });
    } else if (
      getbaseUrl === "/industry_publication_" ||
      getbaseUrl === "/country_publication_"
    ) {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          navigate("/search", {
            replace: true,
            state: {
              data: { name: k._id, compnumber: k.compnumber },
              baseurl: getbaseUrl,
              companylist: location.state.companylist,
              fdata: location.state.data,
            },
          });
        }
      });
    } else if (getbaseUrl === "/search") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          setData(k);
          setShowDRModal(true);
          navigate("/publication_report", {
            replace: true,
            state: { data: k, breadcrumb: props.breadcrumb },
          });
        }
      });
    } else if (getbaseUrl === "/configuration_gui") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          setData(k);
          // setShowDetailModal(true);
          navigate("/config_gui_setting", {
            replace: true,
            state: { data: k, dataset: props.data },
          });
        }
      });
    } else if (getbaseUrl === "/user_management") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          setData(k);
          setShowUModal(true);
        }
      });
    } else if (getbaseUrl === "/industry_mapping") {
      props.data.map((k: any, i: any) => {
        if (data.key === k._id) {
          if (
            props.selectedIndustryCode &&
            typeof props.selectedIndustryCode == "function"
          ) {
            props.selectedIndustryCode(k);
          }
        }
      });
    }
  };

  const closeEditModal = () => {
    setData(null);
    setShowEditModal(false);
  };

  const closeCreateModal = () => {
    setData(null);
    setShowCreateModal(false);
  };

  const closeCPModal = () => {
    setData(null);
    setShowCPModal(false);
  };

  const closeIPModal = () => {
    setData(null);
    setShowIPModal(false);
  };

  const closeDRModal = () => {
    setData(null);
    setShowDRModal(false);
  };

  const closeCPPModal = () => {
    setData(null);
    setShowCPPModal(false);
  };

  const closeUModal = () => {
    setData(null);
    setShowUModal(false);
  };

  const closeAlert = () => {
    setData(null);
    setShowAlert(false);
  };

  const closeRemoveAlert = () => {
    setShowDConfirm(false);
  };

  const confirmDelete = () => {
    removeMultiData(removeData);
    // if (props.removeMultiData && typeof (props.removeMultiData)==='function') {
    //     props.removeMultiData(pdata)
    // }
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const getexportCSV = (key: any) => {
    if (props.handleExport && typeof props.handleExport === "function") {
      props.handleExport(key);
    }
  };

  const tagopenMore = () => {
    setOpenMore(!openMore);
  };

  const removeMultiData = (data: any) => {
    const tempPageControl: PageControl = props.pageControl;
    tempPageControl.recPerPage = currentNumPerPage;
    if (props.type === "large") {
      if (props.handleDelete && typeof props.handleDelete === "function") {
        props.handleDelete(data, { tempPageControl, dataFilter, sortControl });
      }
    } else {
      if (props.handleDelete && typeof props.handleDelete === "function") {
        props.handleDelete(data);
      }
    }
  };

  const changeDatasource = (el: any) => {
    if (
      props.changeDatasource &&
      typeof props.changeDatasource === "function"
    ) {
      props.changeDatasource(el);
    }
  };

  const showLeftAction = () => {
    if (props.leftAction) {
      return props.leftAction.map((obj: any, i: any) => {
        if (localStorage.getItem("privilage") === "Admin") {
          if (obj === "export") {
            return (
              <div
                style={{
                  background: "transparent",
                  // borderRadius: "6px",
                  border: "0px",
                  borderColor: "white",
                  paddingLeft: "10%",
                  cursor: "pointer",
                  color: "black",
                }}
              >
                <Dropdown className="export-dropdown" menu={{ items, onClick }}>
                  <Button>
                    <DownloadOutlined style={{ color: "black" }} />
                    Download File
                  </Button>
                </Dropdown>
              </div>

              // <Button
              //   size="large"
              //   style={{
              //     background: "transparent",
              //     // borderRadius: "6px",
              //     border: "0px",
              //     borderColor: "white",
              //     // paddingRight: "10%",
              //     cursor: "pointer",
              //     color: "black",
              //   }}
              //   icon={<DownloadOutlined style={{ color: "black" }} />}
              //   onClick={getexportCSV}
              // >
              //   {/* Download File Inventory */}
              //   Download
              // </Button>
            );
          }
        }
      });
    }
  };

  const showAction = () => {
    if (props.action) {
      return props.action.map((obj: any, i: any) => {
        if (obj === "reset") {
          if (getbaseUrl === "/home") {
            return (
              <Button
                type="link"
                size="large"
                style={{
                  background: "transparent",
                  borderRadius: "6px",
                  border: "0px",
                  cursor: "pointer",
                  color: "#DF4662",
                  textDecoration: "underline",
                  textDecorationColor: "#DF4662",
                }}
                onClick={resetFilterHome}
              >
                Reset
              </Button>
            );
          } else {
            return (
              <Button
                type="link"
                size="large"
                style={{
                  background: "transparent",
                  borderRadius: "6px",
                  border: "0px",
                  cursor: "pointer",
                  color: "#DF4662",
                  textDecoration: "underline",
                  textDecorationColor: "#DF4662",
                }}
                onClick={resetFilter}
              >
                Reset
              </Button>
            );
          }
        }
        if (localStorage.getItem("privilage") === "Admin") {
          if (obj === "create") {
            return (
              <Button
                size="large"
                style={{
                  background: "#2DBBDA",
                  borderRadius: "6px",
                  border: "0px",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={createNew}
              >
                + Create
              </Button>
            );
          }
          if (props.checkedData?.length > 0) {
            if (obj === "delete") {
              return (
                <Button
                  size="large"
                  style={{
                    background: "red",
                    borderRadius: "6px",
                    border: "0px",
                    cursor: "pointer",
                    color: "white",
                    marginRight: "15px",
                  }}
                  onClick={deleteAll}
                >
                  Delete
                </Button>
              );
            }
          }
        }
        if (obj === "showempty") {
          // props.cols.map((fobj:any)=>{

          // })

          return (
            <Button
              size="large"
              style={{
                background: "#2DBBDA",
                borderRadius: "6px",
                border: "0px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={showemptyonly}
            >
              Show Empty First
            </Button>
          );
        }
      });
    }
  };

  const showAdvancedAction = () => {
    if (props.action) {
      return props.action.map((obj: any, i: any) => {
        if (obj === "adsearch") {
          return (
            <Select
              listHeight={160}
              placement="bottomLeft"
              getPopupContainer={(trigger) => trigger.parentElement}
              defaultValue={props.showData}
              onChange={changeDatasource}
              style={{ width: 300, marginRight: "15px" }}
              options={[
                {
                  value: "companywithpublications",
                  label: "Company with Publications",
                },
                // {value:"company_without _publications",label:"Company without Publications"},
                { value: "allcompany", label: "Company with All" },
              ]}
            />
          );
        }
        if (obj === "datefilter") {
          return <RangePicker style={{ width: 300 }} />;
        }
      });
    }
  };

  const showPrevious = () => {
    if (props.action) {
      return props.action.map((obj: any, i: any) => {
        if (obj === "back") {
          return (
            <Button
              type="link"
              size="large"
              style={{
                background: "transparent",
                borderRadius: "6px",
                border: "0px",
                cursor: "pointer",
                color: "#DF4662",
                textDecoration: "underline",
                textDecorationColor: "#DF4662",
              }}
              icon={<RollbackOutlined />}
              onClick={GoBack}
            >
              Back
            </Button>
          );
        }
      });
    }
  };

  const GoBack = () => {
    if (getbaseUrl === "/industry_publication_") {
      navigate("/industry_publication", { replace: true });
    } else if (getbaseUrl === "/country_publication_") {
      navigate("/country_publication", { replace: true });
    }
  };

  return (
    <div>
      {props.type === "large" && (
        <>
          <AlertModal
            show={showAlert}
            onClose={closeAlert}
            alertmessage={alertmessage}
            alerttype={alerttype}
            alertmsg
          />
          <AlertModal
            show={showDConfirm}
            onClose={closeRemoveAlert}
            data={removeData}
            deleteDataHook={confirmDelete}
            multipleDelete
          />
          <div
            style={{ width: "100%", margin: "0px 0px", padding: "0px 4.5%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div>{showLeftAction()}</div>
              <div>{showAction()}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div></div>
              <div>{showAdvancedAction()}</div>
            </div>
          </div>

          {/* <DetailModal 
                        onClose={closeCreateModal} 
                        show={showCreateModal} 
                        countrylist={countrylist}
                        industrylist={industrylist}
                        editedDataHook={ConfirmChanges}
                        handleAlertMessage={handleAlertMessage} 
                        handleAlertType={handleAlertType}
                        create
                    /> */}
          <DetailModal
            onClose={closeCreateModal}
            show={showCreateModal}
            selected={pdata}
            data={pdata}
            countrylist={countrylist}
            industrylist={industrylist}
            editedDataHook={ConfirmChanges}
            handleAlertMessage={handleAlertMessage}
            handleAlertType={handleAlertType}
            loading={loading}
            create
          />
          {/* <DetailModal 
                        onClose={closeEditModal} 
                        show={showEditModal} 
                        data={pdata} 
                        countrylist={countrylist}
                        industrylist={industrylist}
                        editedDataHook={ConfirmChanges}
                        handleAlertMessage={handleAlertMessage} 
                        handleAlertType={handleAlertType}
                        edit
                    /> */}
          <DetailModal
            onClose={closeCPModal}
            show={showCPModal}
            data={pdata}
            companylist={companylist}
            industrylist={industrylist}
            countrylist={countrylist}
            countryeditor
          />

          <DetailModal
            onClose={closeIPModal}
            show={showIPModal}
            data={pdata}
            companylist={companylist}
            industrylist={industrylist}
            countrylist={countrylist}
            industryeditor
          />

          <Table
            pagination={
              {
                total: props.pageControl.numRec,
                current: currentPage,
                pageSizeOptions: [10, 50, 100],
                onChange: (page: any) => {
                  changePages(page);
                },
                onShowSizeChange: (current: any, size: any) =>
                  `${changeNumberPage(current, size)}`,
                showTotal: (total: any) =>
                  `${getPageRecords()[0]}-${
                    getPageRecords()[1]
                  } of ${total} items`,
              } as TablePaginationConfig
            }
            className="myTable"
            style={{
              width: "90%",
              textAlign: "center",
              margin: "auto",
              paddingTop: "10px",
              paddingBottom: "3.5%",
            }}
            bordered={true}
            loading={props.loading}
            dataSource={getLabelData(props.cols, props.data)}
            rowSelection={checkSelection()}
            onChange={onChange}
            scroll={{ x: true }}
          >
            {renderLargeColumns()}
          </Table>
        </>
      )}
      {props.type === "small" && (
        <>
          <AlertModal
            show={showAlert}
            onClose={closeAlert}
            alertmessage={alertmessage}
            alerttype={alerttype}
            alertmsg
          />
          <AlertModal
            show={showDConfirm}
            onClose={closeRemoveAlert}
            data={removeData}
            deleteDataHook={confirmDelete}
            multipleDelete
          />
          {props.action && (
            <div
              style={{
                width: "100%",
                margin: "0px 0px",
                padding: "0px 4.5%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                <div>{showPrevious()}</div>
                <div>{showAction()}</div>
              </div>
            </div>
          )}

          <DetailModal
            onClose={closeCreateModal}
            show={showCreateModal}
            countrylist={countrylist}
            industrylist={industrylist}
            editedDataHook={ConfirmChanges}
            create
          />
          <DetailModal
            onClose={closeEditModal}
            show={showEditModal}
            data={pdata}
            countrylist={countrylist}
            industrylist={industrylist}
            editedDataHook={ConfirmChanges}
            edit
          />

          <DetailModal
            onClose={closeCPModal}
            show={showCPModal}
            data={pdata}
            companylist={companylist}
            industrylist={industrylist}
            countrylist={countrylist}
            countryeditor
          />

          <DetailModal
            onClose={closeIPModal}
            show={showIPModal}
            data={pdata}
            companylist={companylist}
            industrylist={industrylist}
            countrylist={countrylist}
            industryeditor
          />

          <DetailModal
            onClose={closeDRModal}
            show={showDRModal}
            data={pdata}
            companylist={companylist}
            industrylist={industrylist}
            countrylist={countrylist}
            publicationeditor
          />

          <DetailModal
            onClose={closeUModal}
            show={showUModal}
            data={pdata}
            rolelist={rolelist}
            editedDataHook={ConfirmChanges}
            handleAlertMessage={handleAlertMessage}
            handleAlertType={handleAlertType}
            usereditor
          />

          {showDetailModal && (
            <>
              <ConfigManagerDetail
                open={showDetailModal}
                handleClose={handleClose}
                handleCancel={handleCancel}
                handleSaveData={handleSaveData}
                data={pdata}
                regiontype={props.regiontype}
                industrytemplate={props.industrytemplate}
              />
            </>
          )}

          <Table
            pagination={
              {
                current: currentPage,
                pageSizeOptions: [10, 50, 100],
                onChange: (page: any) => {
                  changePages(page);
                },
              } as TablePaginationConfig
            }
            style={{
              width: props.tablewidth,
              textAlign: "center",
              margin: "auto",
              // paddingTop: "10px",
              // paddingBottom: "20px",
              paddingBottom: "3.5%",
            }}
            bordered={true}
            loading={props.loading}
            dataSource={getLabelData(props.cols, props.data)}
            rowSelection={checkSelection()}
            onChange={onChange}
            scroll={{ x: true }}
          >
            {renderSmallColumns()}
          </Table>
        </>
      )}
    </div>
  );
};

export default Component;
